<template>
  <v-expansion-panels focusable multiple v-model="show">
    <v-expansion-panel>
      <v-expansion-panel-header class="subheading font-weight-bold pa-1">
        <h3>{{ $t("studies.acv.grades.title") }}</h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list dense>
          <!-- Traceability -->
          <v-list-item>
            <v-list-item-content>
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="pointer" v-bind="attrs" v-on="on">
                      {{ $t("studies.acv.grades.labels.traceability") }}:
                    </span>
                  </template>
                  <span class="font-weight-bold">
                    {{ grades.traceability }} / 5
                  </span>
                </v-tooltip>
              </span>
            </v-list-item-content>
            <v-list-item-content class="align-end">
              <v-rating
                v-model="grades.traceability"
                background-color="purple lighten-3"
                :readonly="!isEditable"
                color="yellow"
                half-increments
                hover
                small
              ></v-rating>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="showDivider" />

          <!-- Compliance -->
          <v-list-item>
            <v-list-item-content>
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="pointer" v-bind="attrs" v-on="on">
                      {{ $t("studies.acv.grades.labels.compliance") }}:
                    </span>
                  </template>
                  <span class="font-weight-bold">
                    {{ grades.compliance }} / 5
                  </span>
                </v-tooltip>
              </span>
            </v-list-item-content>
            <v-list-item-content class="align-end">
              <v-rating
                v-model="grades.compliance"
                background-color="purple lighten-3"
                :readonly="!isEditable"
                color="yellow"
                half-increments
                hover
                small
              ></v-rating>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="showDivider" />

          <!-- Recyclability -->
          <v-list-item>
            <v-list-item-content>
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="pointer" v-bind="attrs" v-on="on">
                      {{ $t("studies.acv.grades.labels.recyclability") }}:
                    </span>
                  </template>
                  <span class="font-weight-bold">
                    {{ grades.recyclability }} / 5
                  </span>
                </v-tooltip>
              </span>
            </v-list-item-content>
            <v-list-item-content class="align-end">
              <v-rating
                v-model="grades.recyclability"
                background-color="purple lighten-3"
                :readonly="!isEditable"
                color="yellow"
                half-increments
                hover
                small
              ></v-rating>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="showDivider" />

          <!-- Environment -->
          <v-list-item>
            <v-list-item-content>
              <span>{{ $t("studies.acv.grades.labels.environment") }}:</span>
            </v-list-item-content>
            <v-list-item-content v-if="isEditable" class="align-content-end">
              <v-select
                clearable
                hide-details="auto"
                :items="$t('constants.acvEnvironmentGradeTypes.list')"
                outlined
                v-model="grades.environment"
              ></v-select>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-chip class="text-center">
                {{
                  grades.environment ? grades.environment : $t("common.misc.NA")
                }}
              </v-chip>
            </v-list-item-content>
          </v-list-item>

          <!-- Environment xtex -->
          <v-list-item>
            <v-list-item-content>
              <span>
                {{ $t("studies.acv.grades.labels.environment_xtex") }}:
              </span>
            </v-list-item-content>
            <v-list-item-content v-if="isEditable" class="align-content-end">
              <v-select
                clearable
                hide-details="auto"
                :items="$t('constants.acvEnvironmentGradeTypes.list')"
                outlined
                v-model="grades.environment_xtex"
              ></v-select>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-chip class="text-center">
                {{
                  grades.environment_xtex
                    ? grades.environment_xtex
                    : $t("common.misc.NA")
                }}
              </v-chip>
            </v-list-item-content>
          </v-list-item>

          <!-- Environment ademe -->
          <v-list-item>
            <v-list-item-content>
              <span>
                {{ $t("studies.acv.grades.labels.environment_ademe") }}:
              </span>
            </v-list-item-content>
            <v-list-item-content v-if="isEditable" class="align-content-end">
              <v-select
                clearable
                hide-details="auto"
                :items="$t('constants.acvEnvironmentGradeTypes.list')"
                outlined
                v-model="grades.environment_ademe"
              ></v-select>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-chip class="text-center">
                {{
                  grades.environment_ademe
                    ? grades.environment_ademe
                    : $t("common.misc.NA")
                }}
              </v-chip>
            </v-list-item-content>
          </v-list-item>

          <!-- Environment PEF -->
          <v-list-item>
            <v-list-item-content>
              <span>
                {{ $t("studies.acv.grades.labels.environment_pef") }}:
              </span>
            </v-list-item-content>
            <v-list-item-content v-if="isEditable" class="align-content-end">
              <v-select
                clearable
                hide-details="auto"
                :items="$t('constants.acvEnvironmentGradeTypes.list')"
                outlined
                v-model="grades.environment_pef"
              ></v-select>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-chip class="text-center">
                {{
                  grades.environment_pef
                    ? grades.environment_pef
                    : $t("common.misc.NA")
                }}
              </v-chip>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "ProductACVGrades",

  props: {
    grades: { type: Object, default: () => {} },
    isEditable: { type: Boolean, default: false },
    showDivider: { type: Boolean, default: false },
  },
  data() {
    return {
      show: [0],
    };
  },
};
</script>

<style scoped>
.v-expansion-panel-header__icon > i {
  padding-right: 5px !important;
}
</style>
